<template>
    <div class="main-page pt-3">
        <main>
            <div class="container custom-container">
                <section>
                    <div class="table-box p-3">
                        <div class="row">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 d-flex mb-3">
                                <select class="form-select workspace-input" aria-label="Workspace-Title" v-model="project" @change="taskHandler()">
                                    <option value="0" selected>All</option>
                                    <option v-for="option in projects.dataList" :value="option.id" :key="option.id">
                                        {{ option.project_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <h2 class="pb-3">Tasks</h2>
                            <div class="workspace-wrapper d-flex">

                                <div class="workspace-col">
                                    <div class="workspace-col-header border-bottom">
                                        <div class="d-flex px-3 py-2">
                                            <h3>backlog List </h3>
                                            <h3 class="text-right-custom"> Total: {{taskOptions.backlogListTotal}} </h3>
                                        </div>
                                    </div>
                                    <div class="workspace-col-body">
                                        <div class="task-ctl mb-2" v-for="(element, index) in tasks.dataList.backlogList" :key="index">
                                            <Task :element="element"/>
                                        </div>
                                        <div v-if="!tasks.dataList.backlogList">
                                            <skeleton skeleton-total="seletonTotal"/>
                                        </div>
                                    </div>
                                    <div class="workspace-col-footer border-top">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-secondary" type="button" id="backlogList">Load more</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="workspace-col">
                                    <div class="workspace-col-header border-bottom">
                                        <div class="d-flex px-3 py-2">
                                            <h3>todo List</h3>
                                            <h3 class="text-right-custom"> Total: {{taskOptions.todoListTotal}} </h3>
                                        </div>
                                    </div>
                                    <div class="workspace-col-body" >
                                        <div class="task-ctl mb-2" v-for="(element, index) in tasks.dataList.todoList" :key="index">
                                            <Task :element="element"/>
                                        </div>
                                        <div v-if="!tasks.dataList.todoList">
                                            <skeleton skeleton-total="seletonTotal"/>
                                        </div>
                                    </div>
                                    <div class="workspace-col-footer border-top">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-secondary" type="button" id="todoList">Load more</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="workspace-col">
                                    <div class="workspace-col-header border-bottom">
                                        <div class="d-flex px-3 py-2">
                                            <h3>progress List</h3>
                                            <h3 class="text-right-custom"> Total: {{taskOptions.progressListTotal}} </h3>
                                        </div>
                                    </div>
                                    <div class="workspace-col-body" >
                                        <div class="task-ctl mb-2" v-for="(element, index) in tasks.dataList.progressList" :key="index">
                                            <Task :element="element"/>
                                        </div>
                                        <div v-if="!tasks.dataList.progressList">
                                            <skeleton skeleton-total="seletonTotal"/>
                                        </div>
                                    </div>
                                    <div class="workspace-col-footer border-top">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-secondary" type="button" id="progressList">Load more</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="workspace-col">
                                    <div class="workspace-col-header border-bottom">
                                        <div class="d-flex px-3 py-2">
                                            <h3>review List</h3>
                                            <h3 class="text-right-custom"> Total: {{ taskOptions.reviewListTotal }} </h3>
                                        </div>
                                    </div>
                                    <div class="workspace-col-body" >
                                        <div class="task-ctl mb-2" v-for="(element, index) in tasks.dataList.reviewList" :key="index">
                                            <Task :element="element"/>
                                        </div>
                                        <div>
                                            <skeleton v-if="!tasks.dataList.reviewList" skeleton-total="seletonTotal"/>
                                        </div>
                                    </div>
                                    <div class="workspace-col-footer border-top">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-secondary" type="button" id="reviewList">Load more</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="workspace-col">
                                    <div class="workspace-col-header border-bottom">
                                        <div class="d-flex px-3 py-2">
                                            <h3>approve List</h3>
                                            <h3 class="text-right-custom"> Total: {{taskOptions.approveListTotal}} </h3>
                                        </div>
                                    </div>
                                    <div class="workspace-col-body" >
                                        <div class="task-ctl mb-2" v-for="(element, index) in tasks.dataList.approveList" :key="index">
                                            <Task :element="element"/>
                                        </div>
                                        <div v-if="!tasks.dataList.approveList">
                                            <skeleton skeleton-total="seletonTotal"/>
                                        </div>
                                    </div>
                                    <div class="workspace-col-footer border-top">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-secondary" type="button" id="approveList">Load more</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="workspace-col">
                                    <div class="workspace-col-header border-bottom" >
                                        <div class="d-flex px-3 py-2">
                                            <h3>complete List</h3>
                                            <h3 class="text-right-custom"> Total: {{taskOptions.completeListTotal}} </h3>
                                        </div>
                                    </div>
                                    <div class="workspace-col-body">
                                        <div class="task-ctl mb-2" v-for="(element, index) in tasks.dataList.completeList" :key="index">
                                            <Task :element="element"/>
                                        </div>
                                        <div v-if="!tasks.dataList.completeList" >
                                            <skeleton skeleton-total="seletonTotal"/>
                                        </div>
                                    </div>
                                    <div class="workspace-col-footer border-top">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-secondary" type="button" id="completeList">Load more</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <loader v-if="loader"></loader>
        </main>

        <BottomFooter></BottomFooter>
    </div>
</template>

<script>
    import {onMounted, reactive, inject, ref} from "vue";
    import authHeader from "@/common/auth/auth-header";
    import Task from "./Tasks/Task.vue"
    import Loader from "@/common/components/loader/Index";
    import {useStore} from "vuex";
    import BottomFooter from "@/views/layouts/auth/common/BottomFooter";
    import skeleton from '@/common/components/skeleton/Skeleton.vue'

    export default {
        name: "Workspace",
        components: {Task, Loader, BottomFooter, skeleton},
        setup() {
            const project = ref(0)
            let loader = ref(false)
            const axios = inject('axios')

            let smallLoader = reactive({
                backlogListLoader : false,
                todoListLoader : false,
                progressListLoader: false,
                approveListLoader: false,
                completeListLoader: false,
                reviewListLoader: false,
            })

            const store = useStore()
            const company_id = store.getters.getSelectedBoard.id
            let taskOptions = reactive({
                backlogListPage: 1,
                backlogListTotal: 0,
                backlogListlastPage: 1,
                todoListPage: 1,
                todoListTotal: 0,
                todoListlastPage: 1,
                progressListPage: 1,
                progressListTotal: 0,
                progressListlastPage: 1,
                reviewListPage: 1,
                reviewListTotal: 0,
                reviewListlastPage: 1,
                approveListPage: 1,
                approveListTotal: 0,
                approveListlastPage: 1,
                completeListPage: 1,
                completeListTotal: 0,
                completeListlastPage: 1,
            })
            let tasks = reactive({
                dataList: [],
                backlogList: [],
                todoList: [],
                progressList: [],
                reviewList: [],
                approveList: [],
                completeList: [],
                setURL: "",
            });
            let projects = reactive({
                dataList: [],
                setURL: "",
            });
            projects.setURL = vueConfig.Project.ProjectEndPoint;
            tasks.setURL = vueConfig.Project.Task.TaskEndPoint;
            let taksOptions = {
                company_id: company_id
            };
            onMounted(async () => {
                // taskData(tasks.setURL, taksOptions);
                initialLoader();
                await projectData(projects.setURL)
                await infiniteLoader();

            });
            async function taskData (url, options = {}) {
                let setParams = {
                    params: options
                }
                loader = true
                axios.get(url, Object.assign(setParams, authHeader()) )
                    .then((response) => {
                        const resData = response.data.data;
                        if(Object.keys(resData.backlogList.data).length != 0){
                            tasks.dataList.backlogList=resData.backlogList.data;
                            taskOptions.backlogListPage= resData.backlogList.current_page;
                            taskOptions.backlogListTotal= resData.backlogList.total;
                            taskOptions.backlogListlastPage= resData.backlogList.last_page;
                        }
                        if(Object.keys(resData.todoList.data).length != 0){
                            tasks.dataList.todoList = resData.todoList.data
                            taskOptions.todoListPage = resData.todoList.current_page;
                            taskOptions.todoListTotal= resData.todoList.total;
                            taskOptions.todoListlastPage= resData.todoList.last_page;

                        }
                        if(Object.keys(resData.progressList.data).length != 0){
                            tasks.dataList.progressList = resData.progressList.data
                            taskOptions.progressListPage = resData.progressList.current_page;
                            taskOptions.progressListTotal= resData.progressList.total;
                            taskOptions.progressListlastPage= resData.progressList.last_page;
                        }
                        if(Object.keys(resData.reviewList.data).length != 0){
                            tasks.dataList.reviewList = resData.reviewList.data
                            taskOptions.reviewListPage = resData.reviewList.current_page;
                            taskOptions.reviewListTotal= resData.reviewList.total;
                            taskOptions.reviewListlastPage= resData.reviewList.last_page;
                        }
                        if(Object.keys(resData.approveList.data).length != 0){
                            tasks.dataList.approveList = resData.approveList.data
                            taskOptions.approveListPage = resData.approveList.current_page
                            taskOptions.approveListTotal= resData.approveList.total;
                            taskOptions.approveListlastPage= resData.approveList.last_page;
                        }

                        if(Object.keys(resData.completeList.data).length != 0){
                            tasks.dataList.completeList = resData.completeList.data
                            taskOptions.completeListPage = resData.completeList.current_page;
                            taskOptions.completeListTotal= resData.completeList.total;
                            taskOptions.completeListlastPage= resData.completeList.last_page;
                        }
                        
                        
                    })
            }
            const loadMoreTaskData = (url, options = {}, divId, firstLoad) => {
                return new Promise((async (resolve, reject) => {
                    let setParams = {
                        params: options
                    }
                    store.state.pageLoader = false;
                    axios.get(url, Object.assign(setParams, authHeader()) )
                        .then((response) => {
                            const resData = response.data.data;
                            showHideLoader(divId)
                            resolve(resData)
                            switch (divId) {
                                case 'backlogList':
                                    firstLoad?tasks.dataList.backlogList=resData.backlogList.data: tasks.dataList.backlogList.push(...resData.backlogList.data);
                                    taskOptions.backlogListPage = resData.backlogList.current_page;
                                    taskOptions.backlogListTotal= resData.backlogList.total;
                                    taskOptions.backlogListlastPage= resData.backlogList.last_page;
                                    break;
                                case 'todoList':
                                    firstLoad? tasks.dataList.todoList = resData.todoList.data : tasks.dataList.todoList.push(...resData.todoList.data);
                                    tasks.dataList.todoList = resData.todoList.data
                                    taskOptions.todoListPage = resData.todoList.current_page;
                                    taskOptions.todoListTotal= resData.todoList.total;
                                    taskOptions.todoListlastPage= resData.todoList.last_page;
                                    break;
                                case 'progressList':
                                    firstLoad? tasks.dataList.progressList = resData.progressList.data : tasks.dataList.progressList.push(...resData.progressList.data);
                                    taskOptions.progressListPage = resData.progressList.current_page;
                                    taskOptions.progressListTotal= resData.progressList.total;
                                    taskOptions.progressListlastPage= resData.progressList.last_page;
                                    break;
                                case 'approveList':
                                    firstLoad? tasks.dataList.approveList = resData.approveList.data : tasks.dataList.approveList.push(...resData.approveList.data);
                                    taskOptions.approveListPage = resData.approveList.current_page
                                    taskOptions.approveListTotal= resData.approveList.total;
                                    taskOptions.approveListlastPage= resData.approveList.last_page;
                                    break;
                                case 'completeList':
                                    firstLoad? tasks.dataList.completeList = resData.completeList.data : tasks.dataList.completeList.push(...resData.completeList.data);
                                    taskOptions.completeListPage = resData.completeList.current_page;
                                    taskOptions.completeListTotal= resData.completeList.total;
                                    taskOptions.completeListlastPage= resData.completeList.last_page;
                                    break;
                                case 'reviewList':
                                    firstLoad? tasks.dataList.reviewList = resData.reviewList.data : tasks.dataList.reviewList.push(...resData.reviewList.data);
                                    taskOptions.reviewListPage = resData.reviewList.current_page;
                                    taskOptions.reviewListTotal= resData.reviewList.total;
                                    taskOptions.reviewListlastPage= resData.reviewList.last_page;
                            }
                            
                        }).catch((error) => {
                            reject(error)
                        })
                }))
                
            }
            async function projectData (url, options = {}) {
                loader = true
                axios.get(url, authHeader(), {params: options})
                    .then((response) => {
                        loader = false
                        projects.dataList = response.data.data
                    })
            }
            const taskHandler = () => {
                const project_id = project.value; 
                tasks.setURL = vueConfig.Project.Task.TaskEndPointByProjectId;
                let ProjectOptions = {
                    company_id: company_id,
                    project_id: project_id
                };
                taskData(tasks.setURL, ProjectOptions);
            }
            async function initialLoader () {
               loadAllTask ('backlogList') ;
               loadAllTask ('todoList');
               loadAllTask ('progressList');
               loadAllTask ('reviewList');
               loadAllTask ('approveList');
               loadAllTask ('completeList');
            }

            async function infiniteLoader() {
                document.getElementById('backlogList').addEventListener('click', () => {
                    loaderHandler ('backlogList')
                });
                document.getElementById('todoList').addEventListener('click', () => {
                    loaderHandler ('todoList')
                });
                document.getElementById('progressList').addEventListener('click', () => {
                    loaderHandler ('progressList')
                });
                document.getElementById('approveList').addEventListener('click', () => {
                    loaderHandler ('approveList')
                });
                document.getElementById('completeList').addEventListener('click', () => {
                    loaderHandler ('completeList')
                });
                document.getElementById('reviewList').addEventListener('click', () => {
                    loaderHandler ('reviewList')
                });
            }

            const loaderHandler = (divId) => {
                    
                    var isWorking = 0;
                    let currentPage;
                    let lastPage;
                    let statusName;
                    switch (divId) {
                        case 'backlogList':
                            currentPage = taskOptions.backlogListPage;
                            lastPage = taskOptions.backlogListlastPage
                            statusName = 'Draft'
                            break;
                        case 'todoList':
                            currentPage = taskOptions.todoListPage;
                            lastPage = taskOptions.todoListlastPage
                            statusName = 'Assigned'
                            break;
                        case 'progressList':
                            currentPage = taskOptions.progressListPage;
                            lastPage = taskOptions.progressListlastPage
                            statusName = 'Progress'
                            break;
                        case 'approveList':
                            currentPage = taskOptions.approveListPage;
                            lastPage = taskOptions.approveListlastPage
                            statusName = 'Review'
                            break;
                        case 'completeList':
                            currentPage = taskOptions.completeListPage;
                            lastPage = taskOptions.completeListlastPage
                            statusName = 'Approve'
                            break;
                        case 'reviewList':
                            currentPage = taskOptions.reviewListPage;
                            lastPage = taskOptions.reviewListlastPage
                            statusName = 'Submitted'
                            break;
                        default:
                            currentPage = 0;
                    }
                    if(isWorking== 0){
                        isWorking=1;
                        let currentPagePlus = currentPage + 1

                        if(currentPagePlus > lastPage){
                            isWorking=0
                            return false;
                        }
                        setTimeout(() => {
                            showHideLoader(divId)
                            isWorking=0
                            loadMore(currentPagePlus, divId, statusName);
                        }, 100);
                    }
            }

            const loadAllTask = (divId) => {
                    
                    let currentPage;
                    let lastPage;
                    let statusName;
                    switch (divId) {
                        case 'backlogList':
                            currentPage = taskOptions.backlogListPage;
                            lastPage = taskOptions.backlogListlastPage
                            statusName = 'Draft'
                            break;
                        case 'todoList':
                            currentPage = taskOptions.todoListPage;
                            lastPage = taskOptions.todoListlastPage
                            statusName = 'Assigned'
                            break;
                        case 'progressList':
                            currentPage = taskOptions.progressListPage;
                            lastPage = taskOptions.progressListlastPage
                            statusName = 'Progress'
                            break;
                        case 'approveList':
                            currentPage = taskOptions.approveListPage;
                            lastPage = taskOptions.approveListlastPage
                            statusName = 'Review'
                            break;
                        case 'completeList':
                            currentPage = taskOptions.completeListPage;
                            lastPage = taskOptions.completeListlastPage
                            statusName = 'Approve'
                            break;
                        case 'reviewList':
                            currentPage = taskOptions.reviewListPage;
                            lastPage = taskOptions.reviewListlastPage
                            statusName = 'Submitted'
                            break;
                        default:
                            currentPage = 0;
                    }
                    loadMore(currentPage, divId, statusName, true);
            }
            const loadMore = (currentPage, divId, statusName, firstLoad = false) => {
                const taksLoadOptions = {
                    company_id: company_id,
                    status: statusName,
                    page: currentPage
                }
                loadMoreTaskData(tasks.setURL, taksLoadOptions, divId, firstLoad);
            
            }
            const seletonLoopTotal = 10;

            const showHideLoader = (divId) => {
                switch (divId) {
                    case 'backlogList':
                        smallLoader.backlogListLoader = smallLoader.backlogListLoader ? false: true;
                        break;
                    case 'todoList':
                        smallLoader.todoListLoader = smallLoader.todoListLoader  ? false: true
                        break;
                    case 'progressList':
                        smallLoader.progressListLoader = smallLoader.progressListLoader  ? false: true;
                        break;
                    case 'approveList':
                        smallLoader.approveListLoader = smallLoader.approveListLoader  ? false: true;
                        break;
                    case 'completeList':
                        smallLoader.completeListLoader = smallLoader.completeListLoader  ? false: true;
                        break;
                    case 'reviewList':
                        smallLoader.reviewListLoader = smallLoader.reviewListLoader  ? false: true;
                        break;
                    default:
                        smallLoader.backlogListLoader = smallLoader.backlogListLoader  ? false: true ;
                }
            }

            return {
                projects: projects, project, tasks : tasks, taskHandler, loader, smallLoader, taskOptions, seletonTotal: seletonLoopTotal
            }
        }
    }
</script>

<style scoped>
    .workspace-col-header.border-bottom {
        position: relative;
    }
    h3.text-right-custom {
        text-align: right;
        position: absolute;
        right: 8px;
    }
    .spinner-border {
        height: 6rem;
        width: 6rem;
    }
</style>